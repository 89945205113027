* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
#vision .carousel .carousel-item {
  transition-duration: 1s !important;
}
/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
@font-face {
  font-family: "Orbitron ExtraBold";
  src: url(./assets/fonts/orbitron/static/OrbitronExtraBold.ttf)
    format("TrueType");
  font-weight: bold;
}
@font-face {
  font-family: "Century Gothic";
  src: url(./assets/fonts/century-gothic/CenturyGothic.ttf) format("TrueType");
}
@font-face {
  font-family: "Impact";
  src: url(./assets/fonts/impact/impact.ttf) format("TrueType");
}
@font-face {
  font-family: "Gill Sans Light";
  src: url(./assets/fonts/gill-sans/GillSansLight.otf) format("OpenType");
  font-weight: lighter;
}
@font-face {
  font-family: "Engraver MT";
  src: url(./assets/fonts/engraver/EngraverMT.ttf) format("TrueType");
}
:root {
  /* Color */
  --primary-bright-green: #2ced2c;
  --primary-green: #35eb35;
  --primary-dark-green: #00b050;
  --primary-light-green: #9ad35c;
  --primary-dark-green: #00b050;
  --primary-darker-green: #0f793f;
  --primary-gray: #ddd9c3;
  --secondary-gray: #eeece1;
  --primary-light-black: #525049;
  --primary-dark-black: #262626;
}
body {
  font-family: "Century Gothic", sans-serif;
  background: #0c0b09;
  color: #fff;
}

a {
  color: #fff;
  text-decoration: none;
}

a:hover {
  color: #fff;
  text-decoration: none;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  overflow: hidden;
  background: #1a1814;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #1a1814;
  border-top-color: #cda45e;
  border-bottom-color: #cda45e;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
/* Navbar */
.nav-container {
  border: 3px solid var(--primary-green) !important;
  background: #000;
  z-index: 9999;
  position: sticky;
}
.nav-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav-item .menu {
  display: flex;
  margin-right: 84px;
  margin-top: 6px;
  padding-top: 5px;
}
.nav-item .menu .menu-item {
  list-style: none;
  padding: 8px 8px;
  cursor: pointer;
}
.nav-item .menu .menu-item:hover {
  background: var(--primary-green);
  border-radius: 6px;
  transition: 0.3s;
}
/* Card */
.bottom-line {
  width: 300px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 1.5px;
  background: #fff;
}

#hero .service-delivery-conatiner {
  max-width: 300px;
}
.hero-card-globe {
  max-width: 300px;
}
#hero .hero-container .hero-card .service-delivery {
  font-size: 1.2rem;
  line-height: 30px;
}
#hero .hero-container .hero-card .construction {
  font-size: 0.9rem;
}
#hero .hero-container .hero-card .project-management-header {
  font-size: 0.9rem;
}
#hero .hero-container .hero-card .event-management {
  font-size: 0.9rem;
}
#hero .hero-container .hero-card .pmo-office {
  font-size: 0.9rem;
}

.hero-card {
  padding: 20px 12px;
  background: rgb(0, 0, 0, 0.6);
  top: 5%;
  right: 8%;
}
.hero-card .service-delivery {
  font-size: 0.9rem;
}
.hero-card .globe {
  width: 60px;
}
.hero-card .underline-white {
  width: 100%;
  height: 2px;
  border-bottom: 1px solid #fff;
}

.card-two .hero-header {
  font-size: 0.8rem !important;
  color: #fff !important;
}
.hero-card .hero-text-container h1 {
  font-family: "Impact";
  font-weight: 900;
}

.hero-card .service-items h2 {
  font-size: 1rem;
  color: var(--primary-green);
}
.global {
  font-weight: 900 !important;
}
.hero-button-for-services button {
  padding: 12px 80px;
  margin-top: 10px;
  border: none;
  outline: none;
  font-weight: 700;
  text-transform: uppercase;
}
.hero-button-for-services button:hover {
  background: var(--primary-green);
  color: #fff;
}
.hero-border {
  display: flex;
  justify-content: center;
  align-items: center;
}
.hero-border-line {
  width: 60%;
  height: 3px;
  background-color: var(--primary-green);
}
.circle {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: var(--primary-green);
  border-color: var(--primary-green);
}
/* Affiliations */
.affiliations {
  color: var(--primary-green);
}
.globe {
  border: 2px solid var(--primary-green);
}
.btn {
  font-weight: bold;
  cursor: pointer;
  background: rgb(0, 0, 0, 0.6) !important;
  color: #fff;
}
.btn:hover {
  background: rgb(0, 250, 0, 0.6) !important;
  color: #fff;
}
.first-button {
  margin-top: 54px;
}
.second-button {
  margin-top: 12px;
}
.third-button {
  margin-top: 58px;
}
.what-we-do {
  color: var(--primary-green);
  font-weight: bold;
}

/* Service Card */
.project-management-card,
.logistic-card {
  background: var(--primary-light-green);
  padding-top: 50px;
}
.procurement-card {
  background: var(--primary-dark-green);
}
.service-header {
  font-weight: bold;
}
/* Project Management */
#tasks {
  height: 100vh;
}
#tasks,
#approach-to-pm,
#pm-cards,
#procurement {
  background: var(--primary-gray);
}
/* Our Service Overview */
#our-service-overview-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--primary-dark-black);
}
.handshake-details {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px 80px;
}
.handshake-details img {
  border: 2px solid var(--primary-bright-green);
  padding: 10px;
  width: 220px;
}
.handshake-details p {
  color: var(--primary-bright-green);
}
.tasks-description {
  padding: 24px 20px;
}
.tasks-description h2 {
  font-size: 2.4rem;
  font-weight: 700;
}
.approach-to-pm-container {
  background: var(--primary-light-black);
  padding: 24px 16px;
}
.approach-to-pm-container h1 {
  color: var(--primary-bright-green);
  font-size: 2.4rem;
}
.underline-3 {
  width: 63%;
  height: 2px;
  border-bottom: 1px solid var(--primary-bright-green);
}
.possible-cost {
  color: var(--primary-bright-green);
}
.approach-to-pm-description {
  font-size: 2rem;
}
.pm-pentagon-container {
  background: var(--primary-dark-green);
  padding: 20px 30px;
  margin-top: 40px;
  text-align: center;
  color: #0c0b09;
}
.pm-pentagon,
.pm-pentagon-item {
  display: flex;
}
.pm-pentagon-item {
  justify-content: center;
  align-items: center;
}
.debomac-pentagon {
  margin-right: 30px;
}
.pm-pentagon-icon {
  margin-right: 10px;
}
.pm-pentagon-icon img {
  background: #000;
  width: 70px;
  height: 70px;
  padding: 4px;
  border-radius: 50%;
  border: 2px solid #fff;
}
.pm-pentagon-item div p {
  text-align: left;
  background: var(--primary-darker-green);
  padding: 8px;
  border-radius: 10px;
  color: #fff;
}
.pm-pentagon-container .pm-pentagon-description {
  text-align: left;
}
/* Logistics */
.parked-truck {
  background: var(--primary-gray);
  color: #000;
}
.parked-truck .logistics-header {
  font-weight: 900;
}
.logistics-header-container {
  padding: 20px 30px;
}

/* PM Cards */
#pm-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 30px 30px 10px 30px;
}
#pm-cards .pm-card {
  padding: 12px 12px 0px 12px;
}
#pm-cta {
  padding: 20px;
}
.pm-cta-container .pm-cta-header {
  font-size: 3.5rem;
  color: var(--primary-dark-green);
}
.pm-cta-button {
  background: #1b1b1b;
  padding: 0px 5px;
  width: 16%;
}
.pm-cta-button:hover {
  background: var(--primary-darker-green);
}
/* Procurement */
#procurement {
  color: #000;
  padding-bottom: 30px;
}
.procurement-cta {
  display: flex;
}
.about-contact-section {
  display: flex;
  padding-left: 30px;
}

.procurement-cta-header {
  background: #aaa7a7;
  padding: 9px 60px;
  font-size: 1.2rem;
  font-weight: bold;
}

.procurement-cta-header:hover {
  background: #0c0b09;
}
.procurement-images {
  display: flex;
}
.procurement-image-1,
.procurement-image-2 {
  flex: 1;
}
.procurement-image-3 {
  flex: 2;
}
.procurement-description-container {
  margin-left: 30px;
}
.procurement-description {
  width: 90%;
}
.procurement-header {
  font-weight: bold;
  padding-top: 30px;
}
.gallery-sm {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}
.gallery-lg {
  display: none;
}
.conduct {
  background: var(--primary-gray);
  color: #000;
  padding: 20px;
}
.conduct-header {
  padding-top: 20px;
  margin-bottom: 100px;
}
.conduct-items {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

/*--------------------------------------------------------------
# Vision
--------------------------------------------------------------*/
#vision {
  display: flex;
  background: #262626;
}
/* Our Core Team */
.push-boundaries {
  color: var(--primary-green);
}
.team-header {
  font-size: 2.8rem;
  font-weight: lighter;
}
.underline {
  height: 3px;
  width: 20%;
  border-bottom: 1px solid var(--primary-green);
}
.office-position {
  font-style: italic;
  font-weight: lighter;
}
.seasoned-achievers {
  font-size: 1.4rem;
}
.team-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}
.our-core-team {
  display: flex;
  padding-left: 60px;
}
.our-core-team-profile-odd {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}
.our-core-team-profile-even {
  display: flex;
  margin-left: 20px;
}
.team-description-odd {
  width: 75%;
  margin-left: 8px;
}
.team-description-even {
  width: 65%;
  margin-left: 35px;
}
.team-image-icon {
  width: 110px;
  height: 110px;
  border: 6px solid #fff;
  border-radius: 50%;
}
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  overflow: hidden;
}
.hero-with-hand:before {
  content: "";
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
.globe-container {
  z-index: -10 !important;
}
.globe-container,
.hero-item {
  width: 100%;
}
#hero h1 {
  margin-top: 5px;
  font-weight: lighter;
  color: var(--primary-green);
}

#hero h1 span {
  font-size: 3rem;
  font-weight: 700;
  color: var(--primary-green);
}

#hero .btns {
  margin-top: 30px;
}

/* Service Page */
#hero-for-service-page {
  background: url(./assets/img/service-hero.jpg) center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 60vh;
}
#hero-for-career-page {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(./assets/img/career-hero.jpeg) center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.career-quote {
  font-size: 2rem;
}
.career-quote .great-characters {
  font-size: 4rem;
}
.career-name {
  text-align: right;
}
.career-cta {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primary-gray);
  padding: 30px 50px;
}
.career-cta-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.career-cta-globe {
  border: 2px solid var(--primary-green);
  width: 200px;
  padding: 10px;
}
.career-cta-container .career-cta-description {
  font-size: 1.4rem;
  color: #000;
}
.career-cta-container .career-about-us {
  color: var(--primary-green);
  font-weight: bold;
  font-size: 1.2rem;
  padding-left: 30px;
  margin-left: 50px;
}
.career-cta-container .career-about-us:hover,
.policy-statement:hover {
  color: #000;
  cursor: pointer;
}
/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
#about-us {
  display: flex;
  padding-bottom: 20vh;
}
.debomac-container h2 {
  word-spacing: 10px;
}
.about-us-header img {
  border: 2px solid var(--primary-green);
  padding: 10px;
  width: 140px;
}
.about-action-header {
  font-size: 3rem;
  line-height: 4.5rem;
}
.about-us-header h1 {
  font-size: 48px;
}
.about-us-underline {
  height: 2px;
  width: 20%;
  border-bottom: 1px solid #fff;
}
.debomac-header {
  padding-top: 20px;
  line-height: 50px;
}
.green-bg-container h2 {
  color: #35eb35;
  padding-left: 12px;
}
.about-us-description {
  margin-top: 50px;
}
.about-us-description p {
  text-align: justify;
  padding-left: 10px;
  padding-right: 20px;
  color: var(--primary-green);
}
#our-service-overview-2 {
  display: none;
}
/* Debomac Profile */
#about-debomac {
  background: var(--primary-gray);
  padding-top: 80px;
}
.debomac-profile {
  display: flex;
  padding: 0px 20px;
  background: #000;
}
.debomac-images,
.why-join-us,
.logistics-items {
  position: relative;
}
.debomac-images .each-fade {
  position: absolute;
}
.why-join-us-container,
.open-vacancies {
  background: var(--primary-gray);
  padding: 20px 0px 10vh 50px;
}
.open-vacancies-cta-container {
  background: var(--primary-gray);
}

.why-join-us {
  display: flex;
}
.why-join-us .career-img1,
.why-join-us .career-img3 {
  width: 440px;
}
/* .why-join-us .career-img2 {
  width: 200px;
} */
.why-join-us .each-fade {
  position: absolute;
}
.why-join-us img {
  border: 1px solid var(--primary-bright-green);
}
.why-join-us .why-join-us-description {
  background: var(--primary-bright-green);
  padding: 13px;
}
.table {
  width: 100%;
  border-collapse: collapse;
  margin-right: 50px;
}
.table .job-opening-body tr td .apply-now {
  background-color: var(--primary-bright-green);
  font-weight: 700;
  width: 130px;
  text-decoration: none;
  line-height: 35px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 5px;
}
.job-opening-body tr td .apply-now:hover {
  cursor: pointer;
  color: #fff;
  background-color: rgb(7, 182, 7);
}
.job-opening-header th {
  padding: 10px 50px;
  text-align: center;
  border: 1px solid rgb(94, 93, 93);
  background: var(--primary-bright-green);
}
.job-opening-body {
  text-align: center;
  color: #000;
  border: 1px solid rgb(94, 93, 93);
}
.job-opening-body td {
  text-align: center;
  color: #000;
  border-right: 1px solid rgb(94, 93, 93);
}
.logistics-items {
  height: 100vh;
}
.logistics-items .each-fade {
  position: absolute;
}
.debomac-btn {
  top: 180%;
  left: -43%;
}
.debomac .debomac-container h1 {
  font-family: "Orbitron ExtraBold", sans-serif !important;
  margin-top: 20px;
  font-size: 6rem;
}
.debomac .debomac-container h2 {
  font-weight: normal;
  letter-spacing: 6px;
}
.partners {
  display: flex;
}

/* Services */
#recent-projects-lg {
  display: none;
}
#recent-projects-sm {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: var(--secondary-gray);
}
#recent-projects-sm .recent-projects-header {
  font-weight: bold;
}
.service-container {
  padding: 30px;
}
.services {
  display: flex;
}

.service {
  background: rgb(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: calc(100% - 30px);
  margin: 14px;
}
.service h2 {
  font-size: 1.2rem;
  color: var(--primary-green);
}
.service p {
  font-size: 0.8rem;
  font-weight: 200;
}
.service ul li {
  font-size: 0.8rem;
  font-weight: 200;
}

/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
#our-services {
  background: url(./assets/img/laptop-bg.jpg) center center;
  background-size: cover;
}

/*--------------------------------------------------------------
# Partnerships
--------------------------------------------------------------*/
#partnerships {
  background: #fff;
}
.partnerships {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  overflow: hidden;
}
.partnerships-item1 .partnerships-img {
  border: 3px solid var(--primary-darker-green);
  padding: 20px;
}

.partnerships-item2 .partnerships-header {
  color: var(--primary-bright-green);
}
.partnerships-item2 .underline-green1,
.underline-green2 {
  border-bottom: 1px solid var(--primary-bright-green);
  height: 2px;
  margin-bottom: 12px;
}
.partnerships-item2 .underline-green1 {
  width: 20%;
}
.partnerships-item2 .underline-green2 {
  width: 30%;
}
.partnerships-descritpion-container {
  -moz-box-shadow: 10px 10px 24px 6px #ccc;
  -webkit-box-shadow: 10px 10px 24px 6px #ccc;
  box-shadow: 10px 10px 24px 6px #ccc;
}
.partnerships-item2 .partnerships-descritpion {
  color: #fff;
  background: var(--primary-bright-green);
}
.clients-container {
  margin: 12px;
}
/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
#testimonials {
  width: 100%;
  background: var(--secondary-gray);
  padding-bottom: 80px;
  padding-top: 50px;
}
.testimonial-container .testimonial-items {
  display: flex;
  flex-direction: column;
}

.testimonial-container .testimonial-items .testimonial-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.testimonial-container .testimonial-items .testimonial-item img {
  width: 580px;
}

.testimonial-container
  .testimonial-items
  .testimonial-item
  .testimonial-item-description {
  -moz-box-shadow: 10px 10px 24px 6px #ccc;
  -webkit-box-shadow: 10px 10px 24px 6px #ccc;
  box-shadow: 10px 10px 24px 6px #ccc;
}

.testimonial-container .testimonial-header-container .testimonial-header {
  color: var(--primary-bright-green);
}

.testimonial-container .testimonial-header-container .underline-green3 {
  border: 1px solid var(--primary-bright-green);
  height: 2px;
  margin-top: 3px;
  margin-bottom: 12px;
  width: 50%;
}

/*--------------------------------------------------------------
# Events
--------------------------------------------------------------*/
.events {
  background: url(./assets/img/meeting.jpg) center center no-repeat;
  background-size: cover;
  position: relative;
}

.events::before {
  content: "";
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.events .section-title h2 {
  color: #fff;
}
.events .container {
  position: relative;
}
/*--------------------------------------------------------------
# Covid19
--------------------------------------------------------------*/
.covid19 {
  display: flex;
}

.container2 {
  background: #383838;
  padding: 16px 30px;
  text-align: justify;
}

.covid19-header,
.help-header,
.team-header {
  color: var(--primary-green);
}
.help-header-alt {
  margin-top: 30vh;
}
.covid19-header,
.help-header {
  font-weight: lighter;
}
.contact-header {
  background: #383838;
  padding: 9px 60px;
}
.about-contact-section {
  display: flex;
  padding-left: 30px;
}
.about-contact-header {
  background: #1b1b1b;
  padding: 9px 60px;
  font-size: 1.2rem;
}
.about-contact-header:hover {
  background: var(--primary-green);
}
.contact-section {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.contact-item img:hover {
  cursor: pointer;
}

/*--------------------------------------------------------------
# Media Queries
--------------------------------------------------------------*/

/* mobile devices */

@media (max-width: 768px) {
  /*--------------------------------------------------------------
# Navbar 
--------------------------------------------------------------*/
  .nav-item {
    display: none;
  }
  .menu-icons .menu-icon {
    margin: 8px;
  }
  .menu-icons .menu-icon:hover {
    color: var(--primary-bright-green);
  }
  .nav-item.active {
    display: flex;
    flex-direction: column;
  }
  .nav-item .menu {
    display: flex;
    flex-direction: column;
    margin-right: 0px;
  }
  /*--------------------------------------------------------------
# Hero 
--------------------------------------------------------------*/
  #hero .container {
    padding-top: 98px;
  }
  .hero-for-logistics-lg {
    display: none;
  }
  .hero-card-globe {
    margin-top: 30px;
  }
  .hero-button-for-services button {
    font-size: 0.9rem;
  }
  .debomac-profile {
    flex-direction: column;
  }
  .debomac-images {
    background: #000;
    height: 439px;
  }
  .partners .partners-images {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-right: 0px;
  }
  .debomac-container .debomac-header-container .debomac-header {
    font-size: 3rem !important;
  }
  .debomac-container .debomac-header-container .debomac-header-description {
    font-size: 1.1rem !important;
  }
  .partners {
    flex-direction: column;
    align-items: center;
    padding: 8px;
  }
  .partners .partners-logo {
    width: 100px;
  }
  /*--------------------------------------------------------------
# Partnerships 
--------------------------------------------------------------*/
  #partnerships {
    padding-bottom: 10vh;
  }
  .partnerships {
    flex-direction: column;
  }
  .partnerships-item1 .partnerships-img {
    padding: 20px;
    width: 400px;
  }
  .partnerships-item2 .partnerships-descritpion {
    padding: 12px;
  }
  /* Clients */
  .clients-container .client-logo {
    width: 100px;
  }
  .clients-container .client-logo-alt {
    width: 120px;
  }
  .client-logos {
    margin-top: 680px;
  }
  /* Career */
  #hero-for-career-page {
    padding: 10px 30px;
  }
  .career-cta {
    flex-direction: column;
  }
  .career-cta-container .career-cta-description {
    border-top: 1px solid rgb(0, 0, 0, 0.3);
    padding-top: 30px;
    margin-top: 50px;
    text-align: center;
  }
  .career-cta-container .career-about-us {
    text-align: center;
  }

  /* contact */
  .contact-header {
    padding: 13px 60px;
  }
  /*--------------------------------------------------------------
# Covid19
--------------------------------------------------------------*/
  .covid19 {
    flex-direction: column;
  }
  .container2 .covid19-header {
    text-align: left;
    font-size: 1.4rem;
  }
  .container2 p {
    text-align: left;
  }
  /*--------------------------------------------------------------
# Service Cards
--------------------------------------------------------------*/
  .service-cards-container {
    flex-direction: column;
  }
  .project-management-card,
  .logistic-card,
  .procurement-card {
    height: 400px;
    padding-top: 50px;
    cursor: pointer;
  }
  .service-card-icon-one,
  .service-card-icon-three {
    padding-top: 100px;
    width: 80px;
  }
  .service-card-icon-two {
    width: 100px;
    padding-top: 100px;
  }
  .handshake-details p {
    padding-top: 30px;
    margin-top: 30px;
    border-top: 1px solid #fff;
    font-size: 1.5rem;
    text-align: center;
  }
  #about-us {
    flex-direction: column;
  }
  .about-us-description {
    border-left: none;
  }
  .services {
    flex-direction: column;
  }
  /* PM Pentagon */
  .pm-pentagon {
    flex-direction: column;
  }
  .pm-pentagon-items .pm-pentagon-item {
    flex-direction: column;
    padding-top: 24px;
  }
  .pm-pentagon-items .pm-pentagon-item .pm-pentagon-icon {
    padding-bottom: 20px;
  }
  .pm-pentagon .debomac-pentagon img {
    width: 100%;
  }
  .tasks-container .pm-barchart {
    width: 100%;
  }
  /* Procurement Section */
  .conduct-items {
    flex-direction: column;
  }
  /* Our Core Team */
  .our-core-team {
    /* border: 1px solid yellow; */
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  /* Table */
  .table thead {
    display: none;
  }
  .table,
  .table tbody,
  .table tr,
  .table td {
    display: block;
    width: 100%;
  }
  .table tr {
    margin-bottom: 40px;
  }
  .why-join-us {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 80%;
  }
  .table tbody tr td {
    text-align: right;
  }
  .why-join-us-img {
    height: 50vh;
  }
  .job-opening-body td {
    padding: 10px 10px 10px 50%;
    position: relative;
  }
  .table td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-weight: 600;
    font-size: 1rem;
    text-align: left;
  }
  .open-vacancies {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .open-vacancies-cta {
    width: 100%;
  }
  .open-vacancies-cta-container {
    padding: 20px;
    border-bottom: 1px solid var(--primary-gray);
  }
  .job-opening-header th {
    border: none;
  }
  .job-opening-body {
    border: none;
  }
  .job-opening-body td {
    border-right: none;
  }
  /*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
  #footer {
    background: #383838;
    padding: 28px 0 30px 0;
    color: #fff;
    font-size: 14px;
  }

  #footer .footer-top h4 {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    position: relative;
    padding-bottom: 12px;
  }
  .footer-top {
    flex-direction: column;
  }

  #footer .footer-top .footer-links {
    margin-bottom: 30px;
  }

  #footer .footer-top .footer-links ul a {
    color: #fff;
    transition: 0.3s;
    line-height: 1;
  }

  #footer .footer-top .footer-links ul a:hover {
    color: var(--primary-green);
  }

  #footer .copyright {
    text-align: center;
    padding-top: 30px;
  }

  #footer .footer-header {
    font-weight: lighter;
  }

  .footer-links {
    padding-left: 30px;
    border-right: none;
  }

  .remove-border {
    border-right: none !important;
  }

  .footer-links h1 {
    margin-top: 10px;
  }

  .social-icons {
    margin-top: 30px;
  }

  .social-icons img {
    padding: 10px;
  }

  .twitter,
  .instagram {
    margin-left: 20px;
  }
}

@media (max-height: 500px) {
  #hero {
    height: auto;
  }

  #hero .container {
    padding-top: 130px;
    padding-bottom: 60px;
  }
}
@media (max-width: 360px) {
  .partners .partners-images {
    display: grid;
    grid-template-columns: 1fr;
    margin-right: 0px;
  }
  .partners .partners-images .partners-logo {
    padding-top: 8px;
  }
  .client-logos {
    margin-top: 650px;
  }
}
@media (max-width: 280px) {
  .testimonial-container .testimonial-header-container .underline-green3 {
    width: 65%;
  }
}
/* Desktop */
@media (min-width: 769px) {
  .hero-card {
    padding: 40px 24px;
    top: 16%;
    right: 10%;
  }
  .hero-card .globe {
    width: 130px;
    padding: 6px;
  }
  .card-two .hero-header {
    font-size: 1.7rem !important;
  }
  .hero-card .service-items h2 {
    font-size: 4rem !important;
  }
  .menu-icons {
    display: none;
  }
  .container2 p {
    width: 90%;
  }
  .events {
    background-attachment: fixed;
  }

  .debomac .debomac-container {
    padding-right: 300px;
  }
  .debomac-profile {
    height: 439px;
  }

  .debomac .debomac-container .debomac-description {
    padding-top: 30px;
    text-align: justify;
  }
  .debomac-container .debomac-header-container .debomac-header-description {
    padding-left: 4px;
    letter-spacing: 6.1px;
  }

  .tasks-container {
    display: flex;
  }
  .partners {
    align-items: center;
  }
  .affiliations {
    margin-left: 20px;
    margin-right: 30px;
  }

  .partners .partners-logo {
    padding: 30px;
    margin-right: 40px;
  }

  /*--------------------------------------------------------------
# Hero Section 
--------------------------------------------------------------*/
  #hero {
    height: 100vh;
  }
  #hero .service-delivery-conatiner {
    max-width: 450px;
  }
  .hero-card-globe {
    max-width: 450px;
  }
  .hero-button-for-services button {
    font-size: 1.2rem;
  }
  #hero .hero-container .hero-card .service-delivery {
    font-size: 2.8rem;
    line-height: 60px;
  }
  #hero .hero-container .hero-card .construction {
    font-size: 2.4rem;
  }
  #hero .hero-container .hero-card .project-management-header {
    font-size: 2.4rem;
  }
  #hero .hero-container .hero-card .event-management {
    font-size: 2.4rem;
  }
  #hero .hero-container .hero-card .pmo-office {
    font-size: 2.4rem;
  }
  .hero-card .service-items h2 {
    font-size: 4rem;
    color: var(--primary-green);
  }
  #hero h1 {
    margin-top: 20px;
    font-size: 2.8rem;
  }
  .global {
    font-size: 4rem !important;
    text-transform: uppercase;
    font-family: "Orbitron ExtraBold", sans-serif !important;
    font-weight: 900;
  }

  /*--------------------------------------------------------------
# Partnerships 
--------------------------------------------------------------*/
  #partnerships {
    height: 125vh;
  }
  .partnerships {
    flex-direction: row;
    align-items: center;
  }
  .partnerships-item1 .partnerships-img {
    padding: 20px;
    width: 400px;
  }
  .partnerships-item2 .partnerships-descritpion {
    padding: 90px 12px;
    font-size: 1.5rem;
  }
  /* Clients */
  .clients-container .client-logo {
    width: 100%;
    height: 100px;
  }
  .clients-container .client-logo-alt {
    width: 100%;
    height: auto;
  }
  .client-logos {
    margin-top: 580px;
  }

  /*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
  .testimonial-container .testimonial-items {
    display: flex;
    flex-direction: column;
  }
  .testimonial-container .testimonial-items .testimonial-item {
    flex-direction: row;
    align-items: center;
  }
  .testimonial-container .testimonial-items .testimonial-item img {
    width: 580px;
  }
  .testimonial-container .testimonial-header-container .underline-green3 {
    width: 24%;
  }

  /* Service Card */
  .project-management-card,
  .logistic-card {
    height: 600px;
    flex: 33.33;
    padding-top: 50px;
  }
  .project-management-card,
  .logistic-card,
  .procurement-card {
    height: 600px;
    flex: 33.33;
    padding-top: 50px;
    cursor: pointer;
  }
  .handshake-details {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 40px 80px;
  }
  .handshake-details img {
    padding: 10px;
    width: 240px;
  }
  .handshake-details p {
    border-left: 1px solid #fff;
    padding-left: 50px;
    margin-left: 30px;
    font-size: 2rem;
    text-align: justify;
  }
  .service-header {
    font-size: 1.4rem;
  }
  .service-card-icon-one,
  .service-card-icon-three {
    padding-top: 100px;
    width: 80px;
  }
  .service-card-icon-two {
    width: 100px;
    padding-top: 100px;
  }
  #recent-projects-lg {
    display: flex;
    justify-content: center;
    background: var(--secondary-gray);
  }
  #recent-projects-sm {
    display: none;
  }
  .about-us-description {
    border-left: 1px solid #fff;
    margin-top: 80px;
  }
  .about-us-description p {
    padding-left: 30px;
    padding-right: 20px;
  }
  #about-us {
    padding-bottom: 10vh;
  }
  #our-service-overview-1 {
    display: none;
  }
  #our-service-overview-2 {
    display: block;
  }
  #pm-cta {
    height: 50vh;
  }
  /* Procurement Section */
  .gallery-lg {
    display: block;
  }
  .gallery-sm {
    display: none;
  }
  /* Logistics */
  .hero-for-logistics-md {
    display: none;
  }
  .hero-for-logistics-lg {
    display: flex;
    justify-content: center;
  }
  .hero-for-logistics-lg img {
    width: 448px;
  }
  .parked-truck .truck img {
    width: 100%;
    max-height: 520px;
  }
  .logistics-header-container {
    padding: 20px 40px;
  }
  /* Our Core Team */
  .person-odd {
    padding-right: 8px;
  }
  /* Career */
  .career-cta-container .career-cta-description {
    border-left: 1px solid rgb(0, 0, 0, 0.3);
    padding-left: 30px;
    margin-left: 50px;
  }
  .career-cta-items {
    flex-direction: row;
    max-width: 88%;
  }
  .career-quote {
    font-size: 3rem;
  }
  .career-quote .great-characters {
    font-size: 5rem;
  }
  .why-join-us-container {
    display: flex;
    justify-content: center;
  }
  .why-join-us {
    justify-content: center;
    max-width: 88%;
  }
  .why-join-us .career-img1,
  .why-join-us .career-img3 {
    width: 470px;
  }
  .job-opening-body td {
    padding: 10px 50px;
  }
  .open-vacancies {
    padding: 20px 50px 10vh 50px;
  }
  .open-vacancies-cta-container {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid var(--primary-gray);
  }
  .open-vacancies-cta {
    width: 70%;
  }
  /*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
  #footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #383838;
    padding: 28px 0 30px 0;
    color: #fff;
    font-size: 14px;
  }

  #footer .footer-top h4 {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    position: relative;
    padding-bottom: 12px;
  }

  #footer .footer-top .footer-links {
    margin-bottom: 30px;
  }

  #footer .footer-top .footer-links ul a {
    color: #fff;
    transition: 0.3s;
    line-height: 1;
  }

  #footer .footer-top .footer-links ul a:hover {
    color: var(--primary-green);
  }

  #footer .copyright {
    text-align: center;
    padding-top: 30px;
  }

  #footer .footer-header {
    font-weight: lighter;
  }

  .footer-links {
    padding: 10px 0px 10px 100px;
    border-right: 1px solid #fff;
  }

  .remove-border {
    border-right: none !important;
  }

  .footer-links h1 {
    margin-top: 80px;
  }

  .social-icons {
    margin-top: 50px;
  }

  .social-icons img {
    padding: 10px;
  }

  .twitter,
  .instagram {
    margin-left: 20px;
  }
}
